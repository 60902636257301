
import BannerSlider from '~/components/catalog/banner/Slider.vue';

export default {
  name: 'MainSlider',
  components: {BannerSlider},
  props: {
    id: {
      type: String,
      required: true,
    },
    banners: {
      type: Array,
      required: true,
    },
    autoplay: {
      type: [Object, Boolean],
      default: false,
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    container: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isBannerMargin: !this.banners[0]?.color,
      activeSlide: 0,
    }
  },
  computed: {
    sliderStyle() {
      if (!this.banners?.length) return

      const { color, controls_color: controls } = this.banners[this.activeSlide]

      return `background-color: ${color || 'transparent' };--slider-controls-color: ${controls || '#000'};`
    },
  },
  methods: {
    onChangeSlide(index) {
      this.activeSlide = index
    },
  },
}
